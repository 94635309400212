import React from 'react';
import SwitchCheck from './switchcheck';
import SwitchAll from './switchall';

const SwitchContainer = ({name, places, textWidth, float, titleLeftMargin, toggleBtn}) => {

  return (
    <div>
      <div style={{float:'right', marginRight: 10, marginBottom: 15}}>
      <h6 style={{
        color: "white",
        fontSize: 20,
        marginBottom: 15,
        marginLeft: titleLeftMargin,
        //textShadow: "1px 1px 5px black"
      }}>{name}

      </h6>
          {places.map((item) => {
            return (
              <SwitchCheck key={item.title} toggleBtn={() => toggleBtn(item.title)} show={item.show} title={item.title} textWidth={textWidth} float={float}/>
            )
          })}
      </div>
    </div>
    )
}

export default SwitchContainer;
