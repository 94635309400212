//Komponentti yläpalkin logolle (salon logo)

import React from 'react';
import './toprightlogo.styles.css';
import Logo from '../../assets/pictures/salologo.png';

const TopRightLogo = () => {
  return (
    <div className="logo-container">
      <div className="stripe"
      style={{
        position: "absolute",
        right: 55,
        width: 2,
        height: 40,
        backgroundColor: "white",
        borderRadius: 50,
        opacity: 0.4
      }}>
      </div>
      <img className="logo" alt="salologo" src={Logo}></img>
    </div>
  )
}

export default TopRightLogo;