//Tiedotteetsivun pääkomponentti

import React, { lazy, Suspense } from "react";
// import TiedotteetContainer from '../../components/tiedotteet-container/tiedotteet-container.component';
// import bgImage from "../../assets/pictures/tiedotteetbg.jpg";
import bgImage from "../../assets/pictures/salo_talvi.png";
import LoadingScreen from "../../components/loadingscreen/LoadingScreen.component";
import "./yhdistyksetpage.css";

const YhdistyksetContainer = lazy(() =>
  import("./yhdistykset-container/yhdistykset-container.component")
);

const YhdistyksetPage = (props) => {
  return (
    <div
      className="tiedotebg"
      style={{
        background: `url(${bgImage})`,
        backgroundSize: "cover",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Suspense fallback={<LoadingScreen title="tiedotteita" />}>
        <YhdistyksetContainer />
      </Suspense>
    </div>
  );
};

export default YhdistyksetPage;
