  import React, { useRef, useEffect, useState } from "react";
  import "mapbox-gl/dist/mapbox-gl.css";
  import AnimatedPopup from "mapbox-gl-animated-popup";
  import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
  // eslint-disable-next-line import/no-webpack-loader-syntax
  import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
  import MapAlavalikko from "./map.alavalikko";
  import MapInfoContainer from "./mapinfocontainer.component";
  import distance from "@turf/distance";
  import "./map.style.css";
  import PopupContent from "./popupcontent.component.jsx";
  import MarkerSelect from "./markerselect.component.jsx";
  import LivePopup from "./livepopup.component.jsx";
  import ReactDOM from "react-dom";
  import i18next from "i18next";
  const MapBox = ({ switchdata, map }) => {
    const [alavalikko, setAlavalikko] = useState(false);
    const [isInfoContainer, setIsInfoContainer] = useState(false);
  
    const convertString = (juttu) => {
      return juttu
        .replaceAll("ä", "a")
        .replaceAll("ö", "o")
        .replaceAll("Ä", "A")
        .replaceAll("Ö", "O");
    };
  
    const handleToggle = (type, coords) => {
      setImg(icons + convertString(type) + ".png");
      setJsonByDistance(type, coords);
    };
  
    const setJsonByDistance = (type, coords) => {
      setAlavalikko(false);
      fetch(jsons + convertString(type) + ".json")
        .then((response) => {
          return response.json();
        })
        .then((newjson) => {
          newjson.features = newjson.features.filter((ftr)=>{return ftr.properties.lang == i18next.language})
          newjson.features.sort(function (a, b) {
            var aDist = distance(coords, a.geometry.coordinates);
            var bDist = distance(coords, b.geometry.coordinates);
  
            if (aDist > bDist) {
              return 1;
            }
            if (aDist < bDist) {
              return -1;
            }
            return 0;
          });
          setJson(newjson);
          setAlavalikko(true);
        });
    };
  
    //Alavalikon json ja icon url
    const [json, setJson] = useState();
    const [img, setImg] = useState();
  
    const [live, setLive] = useState(false);
    const [liveData, setLiveData] = useState();
    //Tän hetkinen infoikkuna info
    const [currentInfo, setCurrentInfo] = useState({
      data: {properties:{id:0}},
      type: ""
    });
  
    const shouldUpdate = useRef(false);
    const clickedId = useRef();
    const clickedCoords = useRef();
    //Kun klikataan alavalikon korttia
    const moveToPoint = async (id, coords) => {
      clickedId.current = id;
      clickedCoords.current = coords;
      shouldUpdate.current = true;
    };
  
    //Tiedostojen sijainnit
    console.log(window.location.hostname);
    const icons = process.env.PUBLIC_URL + "/dataa/markers/";
    const jsons = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ? process.env.PUBLIC_URL + "/dataa/testjsons/" : process.env.PUBLIC_URL + "/jsons/";
  
    useEffect(() => {
      //Tällä hetkellä alavalikossa oleva kategoria
      var clicked = [];
      var currCategory = "none";
      var isHovering = false;
      var currId = 0;
      //Popup reittien varrella oleville kohteille
      var popup = new AnimatedPopup({
        closeButton: false,
        closeOnClick: false,
        maxWidth: "250px",
        anchor: "top",
        offset: 0,
        openingAnimation: { duration: 300, easing: "easeOutCubic" },
        closingAnimation: { duration: 300, easing: "easeOutCubic" },
      });
      var popup2 = new AnimatedPopup({
        closeButton: false,
        closeOnClick: false,
        maxWidth: "250px",
        offset: 0,
        openingAnimation: { duration: 300, easing: "easeOutCubic" },
        closingAnimation: { duration: 300, easing: "easeOutCubic" },
      });
      if(map.getStyle().layers.length > 38){
        window.location.reload();
      }
      //Reittien varrella oleville kohteille ja reittiviivalle layerit ja sourcet
      map.addSource("points", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });
      map.addSource("route", {
        type: "geojson",
        data: { type: "FeatureCollection", features: [] },
      });
      map.addLayer({
        id: "route",
        type: "line",
        source: "route",
        layout: { "line-join": "round", "line-cap": "round" },
        paint: { "line-color": "#e33549", "line-width": 4 },
      });
      map.loadImage(
        process.env.PUBLIC_URL + "/dataa/markers/" + "Marker.png",
        function (error, image) {
          console.log(switchdata[0].places[0].title);
  
          map.addImage("points", image);
          map.addLayer({
            id: "points",
            type: "symbol",
            source: "points",
            paint: { "text-color": "black" },
            layout: {
              visibility: "visible",
              "text-field": ["get", "name"],
              "text-anchor": "top",
              "text-allow-overlap": true,
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
              "symbol-z-order": "viewport-y",
              "icon-image": "points",
              "text-size": 15,
              "icon-size": 0.28,
              "icon-offset": [0, -60],
            },
          });
          console.log(map.getLayer("points"));
        }
      );
      function clickCheck() {
        if (clicked.length == 0) {
          return;
        } else if (clicked.length == 1) {
          onClickMarker(clicked[0]);
        } else {
          const popupNode = document.createElement("div");
          ReactDOM.render(
            <MarkerSelect
              kohteet={clicked}
              icons={icons}
              click={onClickMarker}
            />,
            popupNode
          );
          if (popup2.isOpen()) popup2.remove();
          popup2
            .setLngLat(clickedCoords.current)
            .setOffset([0, 0])
            .setDOMContent(popupNode)
            .addTo(map);
        }
        clicked = [];
      }
      function onClickMarker(e) {
        var category = e.properties.category;
        var link = e.properties.url;
        var name = e.properties.name;
        var desc = e.properties.description;
        var id = e.properties.id;
  
        currCategory = category;
  
        onClickId(id);
  
        //Zoomataan kohteeseen
        map.flyTo({
          center: e.geometry.coordinates,
          zoom: map.getZoom() + 15 / map.getZoom(),
        });
  
        //Opacity
        for (let item of switchdata) {
          for (let atem of item.places) {
            if (map.getLayer(atem.title))
              map.setPaintProperty(atem.title, "icon-opacity", 0.6);
            map.setPaintProperty(atem.title + "Live", "icon-opacity", 0.6);
          }
        }
        map.setPaintProperty(category, "icon-opacity", 1);
        map.setPaintProperty(category + "Live", "icon-opacity", 1);
        //Laitetaan klikattu layeri päällimmäiseksi
        map.moveLayer(category);
        console.log(e)
        console.log("-----")
        setCurrentInfo({
          data:e,
          type: convertString(category),
        });
        handleToggle(category, e.geometry.coordinates);
      }
  
      //Kun klikataan reitin varrella olevaa kohdetta
      function onClickPoi(e) {
        isHovering = true;
        setTimeout(function () {
          isHovering = false;
        }, 200);
        setAlavalikko(false);
        const popupNode = document.createElement("div");
        ReactDOM.render(
          <PopupContent content={e.features[0].properties.description} />,
          popupNode
        );
        if (popup.isOpen()) popup.remove();
        popup
          .setLngLat(e.features[0].geometry.coordinates)
          .setDOMContent(popupNode)
          .addTo(map);
        map.flyTo({
          center: e.features[0].geometry.coordinates,
          offset: [0, -140],
          zoom: map.getZoom() + 1,
        });
      }
      map.on("click", "points", onClickPoi);
  
      const onClickId = (id) => {
        if (currId == id) return;
        setLiveData();
        if (popup2.isOpen()) {
          popup2.remove();
        }
        currId = id;
        map
          .getSource("points")
          .setData({ type: "FeatureCollection", features: [] });
        map
          .getSource("route")
          .setData({ type: "FeatureCollection", features: [] });
        fetch(jsons + id + "Data.json")
          .then((response) => {
            if (response.ok) return response.json();
            else return { type: "FeatureCollection", features: [] };
          })
          .then((newjson) => {
            
            console.log(newjson)
            if (currId == id) {
              setLiveData(newjson.liveData);
              if (newjson.liveData) {
                const popupNode = document.createElement("div");
                ReactDOM.render(
                  <LivePopup liveData={newjson.liveData} />,
                  popupNode
                );
                popup2
                  .setLngLat(newjson.liveData.coordinates)
                  .setOffset([0, -54])
                  .setDOMContent(popupNode)
                  .addTo(map);
              }
              if (newjson.pointsData)
                map.getSource("points").setData(newjson.pointsData);
              if (newjson.linestringData)
                map.getSource("route").setData(newjson.linestringData);
              map.moveLayer("points");
              map.moveLayer("route");
            }
          });
      };
      function onClickEmpty(e) {
        if (!isHovering) {
          //Jos popup auki suljetaan se
          if (popup2.isOpen()) {
            popup2.remove();
          } else if (popup.isOpen()) {
            popup.remove();
            setAlavalikko(true);
          } else {
            //Tyhjennetään kaikki shitit
            setAlavalikko(false);
            currCategory = "none";
            currId = 0;
            map
              .getSource("points")
              .setData({ type: "FeatureCollection", features: [] });
            map
              .getSource("route")
              .setData({ type: "FeatureCollection", features: [] });
  
            //Opacity normaaliksi
            for (let item of switchdata) {
              for (let atem of item.places) {
                if (map.getLayer(atem.title)) {
                  map.setPaintProperty(atem.title, "icon-opacity", 1);
                  map.setPaintProperty(atem.title + "Live", "icon-opacity", 1);
                }
              }
            }
          }
        }
      }
  
      //Lisätään layerit kaikille kohteille
      map.loadImage(icons + "Live" + ".png", function (error, image) {
        if (error) {
          console.log("couldn't load live icon");
          return;
        }
        map.addImage("Live", image);
        for (let item of switchdata) {
          for (let atem of item.places) {
            let category = atem.title;
  
            map.loadImage(
              icons + convertString(category) + ".png",
              function (error, image) {
                if (error) {
                  console.log(error)
                  console.log("couldn't load " + category);
                  return;
                }
                map.addImage(category, image);
                map.addSource(category + "Live", {
                  type: "geojson",
                  data: { type: "FeatureCollection", features: [] },
                });
                map.addSource(category, {
                  type: "geojson",
                  data: { type: "FeatureCollection", features: [] },
                });
                console.log(category)
                fetch(jsons + convertString(category) + ".json")
                  .then((response) => {
                    if (response.ok) return response.json();
                    else return { type: "FeatureCollection", features: [] };
                  })
                  .then((newjson) => {
                    console.log(newjson)
                    newjson.features = newjson.features.filter((ftr)=>{return ftr.properties.lang == i18next.language})
                    map.getSource(category).setData(newjson);
                    map.getSource(category + "Live").setData(newjson);
                  })
                  .catch((err) => console.log(err));
                //map.addSource(category, {type: 'geojson',data: jsons + convertString(category) + ".json"});
                console.log(category)
  
                map.addLayer({
                  id: category + "Live",
                  type: "symbol",
                  source: category + "Live",
                  layout: {
                    visibility: atem.show ? "visible" : "none",
                    "icon-allow-overlap": true,
                    "icon-ignore-placement": true,
                    "symbol-z-order": "viewport-y",
                    "icon-image": [
                      "case",
                      ["boolean", ["get", "live"], false],
                      "Live",
                      "none",
                    ],
                    "icon-size": 0.3,
                    "icon-offset": [0, -247],
                  },
                });
                map.addLayer({
                  id: category,
                  type: "symbol",
                  source: category,
                  layout: {
                    visibility: atem.show ? "visible" : "none",
                    "icon-allow-overlap": true,
                    "text-allow-overlap": true,
                    "icon-ignore-placement": true,
                    "symbol-z-order": "viewport-y",
                    "icon-image": category,
                    "icon-size": 0.28,
                    "icon-offset": [0, -150],
                    'text-size':[
                        'interpolate', ['linear'], ['zoom'],
                        10, 0,
                        11, 14
                    ]
                  },
                });
  
                //Kun klikataan kohdetta
                map.on("click", category, function (e) {
                  if (currCategory != "none" && currCategory != category) return;
                  if (popup2.isOpen()) {
                    popup2.remove();
                  }
                  isHovering = true;
                  setTimeout(function () {
                    isHovering = false;
                  }, 200);
                  clickedCoords.current = e.lngLat;
                  for (var i = 0; i < e.features.length; i++) {
                    e.features[i].properties.category = category;
                    clicked.push(e.features[i]);
                  }
                  setTimeout(clickCheck, 0);
                });
                //Kun klikataan Live nappia
                /*map.on('click', category+"Live", function (e) {
                  if (currCategory != "none" && currCategory != category)
                  return;
                  if (popup2.isOpen())
                  {popup2.remove();}
                  isHovering = true;
                  setTimeout(function(){isHovering = false;}, 200);
                  clickedCoords.current = e.lngLat;
                  for (var i = 0; i < e.features.length; i++)
                  {
                  e.features[i].properties.category = category;
                  clicked.push(e.features[i]);
                  }
                  setTimeout(clickCheck, 0);
              });*/
                map.off("click", onClickEmpty);
                map.on("click", onClickEmpty);
              }
            );
          }
        }
      });
      //Päivitetään koordinaatit ja reitti jos tarvitsee
      function paivitaJutut() {
        if (shouldUpdate.current === true) {
          shouldUpdate.current = false;
          onClickId(clickedId.current);
          //Zoomataan alavalikossa klikattuun kohteeseen
          var zoomLevel = 14;
          var currCoords = map.getCenter();
          if (
            distance(clickedCoords.current, [currCoords.lng, currCoords.lat]) < 1
          )
            zoomLevel = map.getZoom();
          map.flyTo({ center: clickedCoords.current, zoom: zoomLevel });
        }
      }
      var paivitysjuttu = setInterval(paivitaJutut, 200);
      return () => {
        clearInterval(paivitysjuttu);
        map.off("click", "points", onClickPoi);
        map.off("click", onClickEmpty);
        if (popup.isOpen()) popup.remove();
        if (popup2.isOpen()) popup2.remove();
        if (typeof map.getLayer("points") !== "undefined") {
          map.removeImage("points");
          map.removeLayer("points");
          map.removeSource("points");
        }
        if (typeof map.getLayer("route") !== "undefined") {
          map.removeLayer("route");
          map.removeSource("route");
        }
        for (let item of switchdata) {
          for (let atem of item.places) {
            let category = atem.title;
            if (typeof map.getLayer(category) !== "undefined") {
              map.removeImage(category);
              map.removeLayer(category);
              map.removeSource(category);
              map.removeLayer(category + "Live");
              map.removeSource(category + "Live");
            }
          }
        }
      };
    }, []);
  
    useEffect(() => {
      for (let item of switchdata) {
        for (let atem of item.places) {
          var itemname = atem.title;
          if (map.getLayer(itemname)) {
            map.setLayoutProperty(
              itemname,
              "visibility",
              atem.show ? "visible" : "none"
            );
          }
          if (map.getLayer(itemname + "Live")) {
            map.setLayoutProperty(
              itemname + "Live",
              "visibility",
              atem.show ? "visible" : "none"
            );
          }
        }
      }
    }, [switchdata]);
    return (
      <>
        <div>
           {alavalikko ? <MapAlavalikko id={currentInfo.id} setCurrentInfo={(title, url, id, description, type) => setCurrentInfo({title: title, url: url, id: id, description: description, type: type})} isInfoContainer={isInfoContainer} toggleInfoOff={() => setIsInfoContainer(false)} toggleInfoOn={() => setIsInfoContainer(true)} moveToPoint={(id, coords) => moveToPoint(id, coords)} data={json} img={img} type={currentInfo.type} /> : null}
           {isInfoContainer ? <MapInfoContainer id={currentInfo.id} title={currentInfo.title} urli={currentInfo.url} desci={currentInfo.description} type={currentInfo.type} toggleInfoOff={() => setIsInfoContainer(false)}/> : null}
         </div>
      </>
    );
  };
  
  export default MapBox;
  