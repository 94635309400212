//Tapahtumat -sivun alavalikon kortti

import React, {useState, useEffect} from 'react';
// import InfoContainer from '../infocontainer/infocontainer.component';
import * as BsIcons from 'react-icons/bs';
import parse from 'html-react-parser';
// import SaloLogo from '../../assets/pictures/Salopic.jpg';
import LazyLoad from 'react-lazyload';
import './alavalikko.style.css';

const AlaValikkoCard = ({title, image, date, toggleInfo, id, latlng, move, currentId}) => {

  const [realimage, setRealImage] = useState(image);

  const handle = () => {
    move();
    currentId();
  }

  return (
    <div
    className="alavalikkocard"
    onClick={() => handle()}
    style={{
      backgroundColor: "white",
      height: "100%",
      minWidth: 163,
      maxWidth: 163,
      borderRadius: 10,
      // maxWidth: 150,
      height: 200,
      margin: 5,
      padding: 0,
      marginLeft: 10,
      boxShadow: "1px 1px 7px 1px #101010",
    }}>
      
      <div style={{height: 45, width: "100%"}}>
        <BsIcons.BsInfoCircle onClick={toggleInfo} size={30} color={"#101010"}style={{cursor: 'pointer', float:"right", marginRight: 10, marginTop: 7}}/>
      </div>
      <LazyLoad>
        <img className="cardimage" src={realimage} style={{boxShadow: "0px 1px 5px #101010"}} />
      </LazyLoad>
      <p
      style={{
        position: 'absolute',
        paddingLeft: 5,
        fontSize: 10,
        marginTop: 10,

      }}>{`${date.slice(8,10)}.${date.slice(5,7)}.${date.slice(0,4)}`}</p>
      <div className="textcontainer" style={{
        marginTop: 25,
        overflow: 'hidden',
        height: 60,
        color: "#101010"
        
      }}>
        <h6 
        className="alavalikkocarddata"
        style={{
          fontSize: 10,
          padding: 5,
          color: "#101010"
        }}>{parse(title)}</h6>

      </div>
      
    </div>
  )
}

export default AlaValikkoCard;