import React, {useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';
import MapBox from './map.component';
import RightSideValikko from '../../components/rightsidevalikko/rightsidevalikko.component';
import Dexie from 'dexie';
import {useSwipeable} from 'react-swipeable';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import 'mapbox-gl/dist/mapbox-gl.css';
import './map.style.css';
import LoadingScreen from '../../components/loadingscreen/LoadingScreen.component';
import ReititPage from '../kohteetpage/matkailu.component';
import KurpitsaPage from '../kurpitsasivu/matkailu.component';
import LiikuntapaikatPage from '../liikuntapaikatpage/liikuntapaikat.component';
import NahtavyydetPage from '../nahtavyydetpage/nahtavyydetpage.component';
import HarrastuksetPage from '../yhdistyksetpage/yhdistyksetpage';
import RetkeilyKohteetPage from '../retkeilykohteetpage/retkeilykohteetpage.component';
import RuokaJaJuomaPage from '../ruokajajuomapage/ruokajajuoma.component';
import OstoksetPage from '../ostoksetpage/ostokset.component';
import MajoitusPage from '../majoituspage/majoitus.component';
import KokousJaJuhlaPage from '../kokousjajuhlapage/kokousjajuhla.component';
import TapahtumatPage from '../tapahtumatpage/tapahtumatpage.component';
import VikaIlmoituksetPage from '../vikailmoituksetpage/vikailmoitukset.component';
const MapPageHandler = () => {
  const [path, setPath] = useState("/");
  const location = useLocation();
  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  mapboxgl.workerClass = MapboxWorker;
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
  const mapContainer = useRef();
  const [map, setMap] = useState();
  const geolocate = useRef();
  const mappages = ["/kurpitsa","/reitit","/liikuntapaikat","/retkeily","/nahtavyydet","/ruokajajuoma","/ostokset","/majoitus","/kokousjajuhla", "/tapahtumat", "/vikailmoitukset"];
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    if (mappages.includes(path)){
      if(typeof map != "undefined")
      {
        setShowMap(true);
        map.setCenter([23.128653301065565,60.38498936987718]);
        map.setZoom(16);
      geolocate.current.trigger();}
      else {
    var mapperino = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/juhakolu/ckmlytzxj11w617qiexxawuza?optimize=true',
        center: [23.128653301065565,60.38498936987718],
        zoom: 16
      });
      //Paikanna käyttäjä
      geolocate.current = new mapboxgl.GeolocateControl({
        showAccuracyCircle:false,
        fitBoundsOptions:{zoom: 16},
        auto : true,
      positionOptions: {
      enableHighAccuracy: true
      },
      trackUserLocation: true
      })
      mapperino.addControl(geolocate.current, 'top-left');
      geolocate.current.trigger();
    mapperino.on("load", function (){setMap(mapperino);setShowMap(true);geolocate.current.trigger();});
  }
  }
  else {
    setShowMap(false);
  }
  }, [path]);
  useEffect(() => {
    if(typeof map != "undefined")
    map.resize();
  }, [showMap]);
  return (
    <>
<div>
    {showMap
      ?
      <div>
      <div style={{height: "100%",width: "100%"}}>
        <div style={{height: "100%",width: "100%"}} className="map-container" ref={mapContainer}></div>
      </div>
      {path == "/liikuntapaikat" ? <LiikuntapaikatPage map ={map}/> : null}
      {path == "/reitit" ? <ReititPage map ={map}/> : null}
      {path == "/kurpitsa" ? <KurpitsaPage map ={map}/> : null}
      {path == "/retkeily" ? <RetkeilyKohteetPage map ={map}/> : null}
      {path == "/nahtavyydet" ? <NahtavyydetPage map ={map}/> : null}
      {path == "/ruokajajuoma" ? <RuokaJaJuomaPage map ={map}/> : null}
      {path == "/ostokset" ? <OstoksetPage map ={map}/> : null}
      {path == "/majoitus" ? <MajoitusPage map ={map}/> : null}
      {path == "/kokousjajuhla" ? <KokousJaJuhlaPage map ={map}/> : null}
      {path == "/tapahtumat" ? <TapahtumatPage map ={map}/> : null}
      {path == "/vikailmoitukset" ? <VikaIlmoituksetPage map ={map}/> : null}
      </div>
    :
    <div>
    <div>
      <div style={{
        height: "0%",
        width: "0%",
      }} className="map-container" ref={mapContainer}></div>
    </div>
    {path == "/liikuntapaikat" ? <LoadingScreen title="fitness"/> : null}
    {path == "/reitit" ? <LoadingScreen title="routes"/> : null}
    {path == "/kurpitsa" ? <LoadingScreen title="Kurpitsaviikot"/> : null}
    {path == "/retkeily" ? <LoadingScreen title="nature"/> : null}
    {path == "/nahtavyydet" ? <LoadingScreen title="culture"/> : null}
    {path == "/ruokajajuoma" ? <LoadingScreen title="food"/> : null}
    {path == "/ostokset" ? <LoadingScreen title="shopping"/> : null}
    {path == "/majoitus" ? <LoadingScreen title="housing"/> : null}
    {path == "/kokousjajuhla" ? <LoadingScreen title="meetings"/> : null}
    {path == "/tapahtumat" ? <LoadingScreen title="events"/> : null}
    {path == "/vikailmoitukset" ? <LoadingScreen title="feedback"/> : null}
    </div>
  }
</div>
    </>
  )
};

export default MapPageHandler;
