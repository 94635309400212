//Yksittäinen Linkki Vasemmassa Nav päävalikossa

import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NavbarLeftLink = ({index, item}) => {
  const [t, i18n] = useTranslation("global");
  return (
    <li key={index} className={item.cName}>
      <Link to={item.path}>
        {item.icon}
        <span>{t("components."+item.title)}</span>
      </Link>
    </li>
  )
}

export default withRouter(NavbarLeftLink);
