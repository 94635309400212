import React from 'react';
import './switchcheck.styles.css';
import { useTranslation } from 'react-i18next';

const SwitchCheck = ({title, show ,textWidth, float, toggleBtn}) => {
  const [t, i18n] = useTranslation("global")
  return (
    <div>
      <h6 style={{
        fontSize: 14,
        float: float,
        color: "white",
        width: textWidth,
        //textShadow: "1px 1px 5px black",
      }}>{
      t("categories." + title.split(" ")[0].toLowerCase())
      //title
      }</h6>
      <label className="switch">
        <input onChange={(title) => toggleBtn(title)} type="checkbox" checked={show} />
        <span className="slider round"></span>
      </label>
    </div>
    );
}

export default SwitchCheck;
