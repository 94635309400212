//Vasemman navigointivalikon nimet, pathit ja iconit

import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import * as GiIcons from 'react-icons/gi';
import * as IoIcons from 'react-icons/io5';
import * as FiIcons from 'react-icons/fi';

export const SidebarData = [
  {
    title: 'frontpage',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text'
  },
  {
    title: 'news',
    path: '/tiedotteet',
    icon: <AiIcons.AiOutlineInfoCircle />,
    cName: 'nav-text'
  },
  {
    title: 'events',
    path: '/tapahtumat',
    icon: <BiIcons.BiCalendarEvent />,
    cName: 'nav-text'
  },
/*   {
    title: 'halloween',
    path: '/kurpitsa',
    icon: <GiIcons.GiPumpkinLantern />,
    cName: 'nav-text'
  }, */
  // {
  //   title: 'LiveInfo',
  //   path: '/liveinfo',
  //   icon: <RiIcons.RiLiveLine />,
  //   cName: 'nav-text'
  // },
  {
    title: 'transportation',
    path: '/liikenne',
    icon: <FaIcons.FaBusAlt />,
    cName: 'nav-text'
  },
  {
    title: 'fitness',
    path: '/liikuntapaikat',
    icon: <BiIcons.BiFootball />,
    cName: 'nav-text'
  },
  {
    title: 'routes',
    path: '/reitit',
    icon: <FaIcons.FaRoute />,
    cName: 'nav-text'
  },
/*   {
    title: 'Harrastukset',
    path: '/harrastukset',
    icon: <FaIcons.FaHiking />,
    cName: 'nav-text'
  }, */
  {
    title: 'nature',
    path: '/retkeily',
    icon: <FaIcons.FaCampground />,
    cName: 'nav-text'
  },
  {
    title: 'culture',
    path: '/nahtavyydet',
    icon: <AiIcons.AiOutlineEye />,
    cName: 'nav-text'
  },
  // {
  //   title: 'Yritykset',
  //   path: '/yritykset',
  //   icon: <GiIcons.GiFactory />,
  //   cName: 'nav-text'
  // },
  {
    title: 'food',
    path: '/ruokajajuoma',
    icon: <IoIcons.IoFastFoodOutline />,
    cName: 'nav-text'
  },
  {
    title: 'shopping',
    path: '/ostokset',
    icon: <FiIcons.FiShoppingCart />,
    cName: 'nav-text'
  },
  {
    title: 'housing',
    path: '/majoitus',
    icon: <BiIcons.BiHotel />,
    cName: 'nav-text'
  },
  {
    title: 'meetings',
    path: '/kokousjajuhla',
    icon: <GiIcons.GiFactory />,
    cName: 'nav-text'
  },
  {
    title: 'surveys',
    path: '/kyselyt',
    icon: <AiIcons.AiOutlineQuestionCircle />,
    cName: 'nav-text'
  },
  {
    title: 'feedback',
    path: '/vikailmoitukset',
    icon: <AiIcons.AiOutlineWarning />,
    cName: 'nav-text'
  },
  {
    title: 'information',
    path: '/tietoasovelluksesta',
    icon: <AiIcons.AiOutlineInfoCircle />,
    cName: 'nav-text'
  },

];