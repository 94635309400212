import React from 'react';
import MapKohdeCard from './mapkohde.card';

const MapAlavalikko = ({data, img, toggleInfoOn, moveToPoint, isInfoContainer, toggleInfoOff, setCurrentInfo, type, id}) => {
  return (
    <div
      className="mapalavalikko"
      style={{
        width: "100%",
        position: 'absolute',
        display: "flex",
        flexDirection: 'flex-end',
        bottom: 0,
        paddingLeft: 5,
        paddingTop: 0,
        paddingBottom: 10,
        overflowY: "hidden",
        overflowX: "scroll",
      }}
    >

      {data.features.map(item => {
        return (
          <MapKohdeCard
            setCurrentInfo={(title, url, id, description, type) => setCurrentInfo(title, url, id, description, type)}
            isActive={false}
            img={img}
            item={item}
            key={Math.random() * 1000000}
            title={item.properties.name}
            url={item.properties.url}
            id={item.properties.id}
            selected={item.properties.id==id?true:false}
            description={item.properties.description}
            type={type}
            coords={item.geometry.coordinates}
            toggleInfoOn={toggleInfoOn}
            toggleInfoOff={toggleInfoOff}
            moveToPoint={moveToPoint}
            isInfoContainer={isInfoContainer}
          />
        )
      })}


    </div>
  )
}

export default MapAlavalikko;
