import React, {useEffect, useState} from 'react';
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import './map.style.css';
import MapPalaute from './mappalaute';
import axios from 'axios';
import SpesificFeedbackForm from '../../components/infocontainer/specific-feedback-form';
import parse from "html-react-parser";
import {addPaikkaToDb, getPaikkafromDb, removePaikkaFromDb} from '../../components/dexiedb/dexiedb';


const MapInfoContainer = ({toggleInfoOff, urli, desci, title, id, type, onlyiframe}) => {

  const [liked, setLiked] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const [kyselyData, setKyselyData] = useState({
    Name: "",
    Type: "",
    QuestionText: "",
    Answers: [],
  })

  const checkIfLiked = async(id) => {
    if (await getPaikkafromDb(id))
    {
      setLiked(true);
    }
    else {
      setLiked(false);
    }
  };


  const checkIfKysely = (id) => {
    //for local testing with proxy
    // const url = "/kysely/kyselyt/database/GetSurvey.php";

    const url = process.env.REACT_APP_DATABASE_URL + "GetSurvey.php";
    // const url = "/kysely/kyselyt/database/GetSurvey.php";

    axios({
      method: "POST",
      url: url,
      data: {"Attached": id, "AttachedType": "place"},
      params: {"City": process.env.REACT_APP_CITY}
    })
    .then(res => {
      console.log(res.data[0].Questions[0]);
      if(res.data.length > 0){
        setKyselyData({
          Name: res.data[0].Name,
          Type: res.data[0].Questions[0].Type,
          QuestionText: res.data[0].Questions[0].QuestionText,
          Answers: res.data[0].Questions[0].Answers,
          QuestionIdd: res.data[0].Questions[0].QuestionID
        })
        setIsKysely(true);
      }
      else{
        setIsKysely(false)
      }
    })
    .catch(err => console.log(err))
  }

  const [isKysely, setIsKysely] = useState(false);

  useEffect(() => {
    checkIfLiked(id);
    checkIfKysely(id);
  },[])

  return (
    <div
      className="infocontainer"
      style={{
        padding: 10,
        width: "min(90%, 700px)",
        left: 0,
        right: 0,
        margin: "0 auto",
        height: "80%",
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 1px black",
        position: "fixed",
        zIndex: 0,
        top: "16%",
        borderRadius: 10,
        overflow: 'hidden',
        zIndex: 2
      }}
    >

      {/* header */}
      <div style={{position: "absolute", width: "100%"}}>
        {!onlyiframe
          ?
          <div>
      {liked
        ?<AiIcons.AiFillHeart
          onClick={() => {
            setLiked(false)
            removePaikkaFromDb(id);
          }}
          color={"rgb(150,200,67)"}
          size={35}
          style={{
            position: "absolute",
            cursor: 'pointer'
        }}/>
        :<AiIcons.AiOutlineHeart

          onClick={() => {
            setLiked(true);
            addPaikkaToDb(id, {id, title, desci, urli, type});
          }}
          size={35}
          color={"black"}
          style={{
            position: "absolute",
            cursor: 'pointer'
        }}/>
        }
        <BsIcons.BsPencilSquare
          size={32}
          color={showForm ? "#BEBEbf" : "black"}
          style={{
            position: "absolute",
            left: 40,
            top: 2,
            cursor: 'pointer'
          }}
          onClick={() => setShowForm(!showForm)}
        />
      </div> : null}

          <AiIcons.AiOutlineClose
            color={"black"}
            onClick={toggleInfoOff}
            size={32}
            style={{
              float: 'right',
              backgroundColor: "lightgrey",
              borderRadius: 50,
              padding: 3,
              marginTop: 2,
              marginRight: 20,
              cursor: 'pointer'
            }}
          />
      </div>


      {/* IFRAME content */}
      {!showForm
      ?
      <div>
        {typeof urli != 'undefined' && urli != null
          ?
          <div>
        <iframe
          seamless="seamless"
          className="iframe"
          sandbox="allow-scripts"
          src={urli.replaceAll("http:", "https:")}
          style={{position: "absolute", left:0, top: 50, height: "100%", border: "none", width: "100%"}}
        />
      </div>
      :
      <div>
      {typeof desci != 'undefined' && desci != null
        ?
        <div style={{overflow:"auto", position: "absolute",  top: 50, bottom: 0, fontSize: 20, color: "#234d5f"}}>
      {parse(desci.replaceAll("href", "aaa"))}
    </div> : <div style={{overflow:"auto", position: "absolute",  top: 50, bottom: 0, fontSize: 20, color: "#234d5f"}}>{title}</div> }
    </div>
  }
      </div>
      // palaute formi tähän, liikuntapaikoille
      : <div>
        {!isKysely
        ? <MapPalaute handleToggle={toggleInfoOff} title={title} id={id}/>
        : <SpesificFeedbackForm handleToggle={toggleInfoOff} kyselyData={kyselyData}/>
        }

      </div>



      }
    </div>
  )
}

export default MapInfoContainer;
