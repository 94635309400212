//Vikailmoitukset Form -komponentti

import React, { useState } from "react";
import "./vikailmoitukset.style.css";
import selfmark from "../../assets/pictures/markers/whitemarker.png";
import axios from "axios";
import { useTranslation } from "react-i18next";

const VikaIlmoituksetForm = ({ long, lat, setAddEntry, t }) => {
  //Schema lähetettävälle vikailmoitusdatalle
  const [formData, setFormData] = useState({
    Subject: "",
    Feedback: "",
    Lat: lat,
    Long: long,
  });

  const url = process.env.REACT_APP_DATABASE_URL + "LocationFeedback.php";
  // const url = "/kysely/kyselyt/database/LocationFeedback.php";

  //Lähettää vikailmoitusdatan backendille
  const postData = (data) => {
    axios({
      method: "POST",
      url: url,
      data: data,
      params: { City: process.env.REACT_APP_CITY },
    })
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postData(formData);
    console.log(formData);
    setAddEntry();
  };

  return (
    <div style={{ width: 350 }}>
      <div className="vikaheader">
        <img
          title={`${long}, ${lat}`}
          src={selfmark}
          width={40}
          style={{ position: "absolute", right: 10, top: 10 }}
        />
        <h5
          style={{
            fontSize: 25,
            padding: 15,
            color: "white",
            textShadow: "1px 1px 5px black",
          }}
        >
          {t("report.headline")}
        </h5>
      </div>
      <div>
        <div>
          <h6 style={{ marginTop: 60, marginLeft: 10 }}>{t("report.category")}</h6>
          <div style={{ marginLeft: 10 }}>
            <div
              onChange={(e) =>
                setFormData({ ...formData, Subject: e.target.id })
              }
            >
              <div>
                <label>
                  <input
                    className="radioin"
                    type="radio"
                    id="liikuntapaikat"
                    name="aihe"
                  />
                  <span>{t("report.exercise")}</span>
                </label>
              </div>
              <div>
                <label>
                  <input
                    className="radioin"
                    type="radio"
                    id="rakennukset"
                    name="aihe"
                  />
                  <span>{t("report.buildings")}</span>
                </label>
              </div>
              <div>
                <label>
                  <input
                    className="radioin"
                    type="radio"
                    id="venepaikat"
                    name="aihe"
                  />
                  <span>{t("report.harbour")}</span>
                </label>
              </div>
              <div>
                <label>
                  <input
                    className="radioin"
                    type="radio"
                    id="kadut"
                    name="aihe"
                  />
                  <span>{t("report.streets")}</span>
                </label>
              </div>

              <div style={{ position: "absolute", top: 97, left: 150 }}>
                <label>
                  <input
                    className="radioin"
                    type="radio"
                    id="puistoalueet"
                    name="aihe"
                  />
                  <span>{t("report.playground")}</span>
                </label>
              </div>
              <div style={{ position: "absolute", top: 125, left: 150 }}>
                <label>
                  <input
                    className="radioin"
                    type="radio"
                    id="matonpesupaikat"
                    name="aihe"
                  />
                  <span>{t("report.carpet")}</span>
                </label>
              </div>
              <div style={{ position: "absolute", top: 154, left: 150 }}>
                <label>
                  <input
                    className="radioin"
                    type="radio"
                    id="roskaaminen"
                    name="aihe"
                  />
                  <span>{t("report.litter")}</span>
                </label>
              </div>
              <div style={{ position: "absolute", top: 182, left: 150 }}>
                <label>
                  <input
                    className="radioin"
                    type="radio"
                    id="jokumuu"
                    name="aihe"
                  />
                  <span>{t("report.misc")}</span>
                </label>
              </div>
            </div>
            <div
              onChange={(e) =>
                setFormData({ ...formData, Feedback: e.target.value })
              }
              style={{ marginTop: 5 }}
            >
              <textarea
                maxlength="300"
                style={{
                  margin: "0px auto",
                  border: "1px solid grey",
                  borderRadius: 3,
                  minHeight: 70,
                  maxHeight: 70,
                  minWidth: "96%",
                  maxWidth: "96%",
                  padding: 5,
                  lineHeight: 1,
                }}
                type="text"
                placeholder={t("report.placeholder")}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: "absolute", right: 20 }}>
        {formData.Feedback.length + "/" + 300}
      </div>
      <div style={{ textAlign: "center" }}>
        <button
          onClick={(e) => handleSubmit(e)}
          className="btn"
          style={{
            height: 35,
            marginLeft: 0,
            marginTop: 5,
            backgroundColor: "rgb(13, 104, 139)",
            width: 150,
            color: "white",
          }}
        >
          {t("report.send")}
        </button>
      </div>
    </div>
  );
};

export default VikaIlmoituksetForm;
