import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import HttpsRedirect from "react-https-redirect";
import "mapbox-gl/dist/mapbox-gl.css";
import global_en from "./translations/en/global.json";
import global_fi from "./translations/fi/global.json";
import { I18nextProvider } from 'react-i18next';
import detector from "i18next-browser-languagedetector";
import Backend from "i18next-localstorage-backend";
import i18next from "i18next";

i18next.use(detector).use(Backend).init({
  interpolation: {escapeValue: false},
  lng: localStorage.getItem("i18nextLng") || "fi",
  backend: {
    // options for the backend
    keySeparator: false, // we don't want to use the default `.` separator
    // the prefix used to store the language in local storage
    prefix: "i18next_",
    // the expiration time for the language cache in milliseconds
    expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
    // the version of the language cache
    version: "1.0",
  },
  resources:{
    fi: {
      global: global_fi
    },
    en: {
      global: global_en
    }
  }
})

ReactDOM.render(
  <>
    <HttpsRedirect>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </HttpsRedirect>
  </>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
