import React, {useState, useEffect} from 'react';
import parse from "html-react-parser";
const LivePopup = ({liveData}) => {

  return (
    <div style={{color:"black", fontSize:"15px", overflowY:"scroll", maxHeight:"200px", display: "flex",
    flexDirection: 'column', padding:'10px'}}>

      <p
        style={{
          position: 'relative',
          verticalAlign: "middle",
          textAlign: 'center',

          fontSize: 20,
          fontWeight: 550
        }}
        >
      {liveData.name}
      </p>
      <img
        src={ ((liveData.img.includes("admin.taskussa.info/")) ? "" : "https://www.admin.taskussa.info/") + liveData.img}
        style={{
          position: 'relative',
          width: "97%",
        }}
        />
      <p
        style={{
          marginTop:5,
          fontSize: 15,
          fontWeight: 20,
          padding: 5,
          backgroundColor: "rgb(243, 244, 244)"
        }}
        >
      {liveData.description}
      </p>
    </div>
  )
}

export default LivePopup;
