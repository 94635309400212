import React, { useRef, useEffect, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import "./tapahtumatpage.style.css";
import InfoContainer from "../../components/infocontainer/infocontainer.component";
import MarkerSelect from "./markerselect.component.jsx";
import ReactDOM from "react-dom";
import AnimatedPopup from "mapbox-gl-animated-popup";
import i18next from "i18next";
const TapahtumatMap = ({ data, latt, lngg, currentId, resetId, map }) => {
  const [infoShow, setInfoShow] = useState(false);
  const handleToggle = () => setInfoShow(false);

  const [currentInfoData, setCurrentInfoData] = useState({
    title: "",
    date: "",
    link: "",
    id: "",
    image: "",
    latlng: [],
  });

  const toggleInfo = (item) => {
    setCurrentInfoData({
      title: item.properties.name,
      date: item.properties.date,
      link: item.properties.url,
      id: item.properties.id,
      image: item.properties.image,
      latlng: [item.geometry.coordinates[1], item.geometry.coordinates[0]],
    });
    setInfoShow(true);
  };

  const filterData = (featureData) => {
    return {
      type: "FeatureCollection",
      features: featureData.features.filter((item) => {
        return item.geometry.coordinates[0] != null;
      }),
    };
  };

  const icons = process.env.PUBLIC_URL + "/dataa/markers/";
  
  useEffect(() => {

    map.addSource("tapahtumat", {
      type: "geojson",
      data: { type: "FeatureCollection", features: [] },
      filter: ["==", ["get", "lang"], i18next.language],
    });

    map.loadImage(icons + "Tapahtumat" + ".png", function (error, image) {
      if (error) {
        console.log("couldn't load " + "tapahtumat icon");
        return;
      }
      map.addImage("tapahtumat", image);
      map.addLayer({
        id: "tapahtumat",
        type: "symbol",
        source: "tapahtumat",
        layout: {
          visibility: "visible",
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "symbol-z-order": "viewport-y",
          "icon-image": "tapahtumat",
          "icon-size": 0.28,
          "icon-offset": [0, -74],
        },
      });
    });

    return () => {
      if (typeof map.getLayer("tapahtumat") !== "undefined") {
        map.removeLayer("tapahtumat");
        map.removeImage("tapahtumat");
      }
      map.removeSource("tapahtumat");
    };
  }, []);

  useEffect(() => {
    var popup = new AnimatedPopup({
      closeButton: false,
      closeOnClick: false,
      maxWidth: "250px",
      anchor: "bottom",
      offset: 25,
      openingAnimation: { duration: 300, easing: "easeOutCubic" },
      closingAnimation: { duration: 300, easing: "easeOutCubic" },
    });

    var clicked = false;

    function onClickEmpty(e) {
      if (clicked) {
        clicked = false;
        return;
      }

      if (popup.isOpen()) {
        popup.remove();
      }
    }

    function onClickTapahtuma(e) {
      toggleInfo(e);
      if (popup.isOpen()) {
        popup.remove();
      }
    }
    
    function onClickTapahtumat(e) {
      clicked = true;
      const popupNode = document.createElement("div");
      ReactDOM.render(
        <MarkerSelect
          tapahtumat={e.features}
          icons={icons}
          click={onClickTapahtuma}
        />,
        popupNode
      );
      if (popup.isOpen()) popup.remove();
      popup
        .setLngLat(e.features[0].geometry.coordinates)
        .setDOMContent(popupNode)
        .addTo(map);
      map.flyTo({ center: e.features[0].geometry.coordinates });
    }
    map.getSource("tapahtumat").setData(filterData(data));
    map.on("click", "tapahtumat", onClickTapahtumat);
    map.on("click", onClickEmpty);
    return () => {
      map.off("click", onClickEmpty);
      if (popup.isOpen()) {
        popup.remove();
      }
      map.off("click", "tapahtumat", onClickTapahtumat);
    };
  }, [data]);

  useEffect(() => {
    if (lngg + latt == 0) {return;}
    map.flyTo({ center: [lngg, latt], zoom: 16 });
  }, [currentId]);

  return (
    <>
      {infoShow ? (
        <InfoContainer data={currentInfoData} handleToggle={handleToggle} />
      ) : null}
    </>
  );
};

export default TapahtumatMap;
