import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
const MarkerSelect = ({ tapahtumat, icons, click }) => {
  return (
    <div
      style={{
        color: "white",
        fontSize: "15px",
        overflowY: "scroll",
        maxHeight: "200px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {tapahtumat.map((item) => {
        return (
          <div
            style={{
              marginBottom: 5,
              backgroundColor: "#234d5f",
              borderRadius: 5,
              minWidth: 50,
              maxWidth: 250,
              padding: 5,
            }}
            onClick={() => {
              click(item);
            }}
          >
            {item.properties.name}{" "}
            {`${item.properties.date.slice(8, 10)}.${item.properties.date.slice(5,7)}.${item.properties.date.slice(0, 4)}`}
          </div>
        );
      })}
    </div>
  );
};

export default MarkerSelect;
