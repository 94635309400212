//TapahtumatSivun Pääkomponentti

import React, { useState, useEffect } from "react";
import TapahtumatMap from "./tapahtumatmap.component";
import AlaValikko from "../../components/alavalikko/alavalikko.component";
import i18next from "i18next";

const TapahtumatPage = ({ map }) => {
  const HOST = window.location.hostname;

  const [page, setPage] = useState(1);
  const [data, setData] = useState({ type: "FeatureCollection", features: [] });

  const [currentPos, setCurrentPos] = useState([0, 0]);
  const [currentId, setCurrentId] = useState(null);

  const handleChange = (lat, lng) => {
    if (!lat) {return;}
    setCurrentPos([lat, lng]);
  };

  useEffect(() => {
    const jsonPath = (HOST === "localhost" || HOST === "127.0.0.1")
        ? process.env.PUBLIC_URL + "/dataa/testjsons/"
        : process.env.PUBLIC_URL + "/jsons/";
        
    fetch(jsonPath + "Tapahtumat.json")
      .then((response) => {
        if (!response.ok){ return { type: "FeatureCollection", features: [] }; }
        return response.json();
      })
      .then((events) => {
        events.features = events.features.filter((i) => {
          return i.properties.lang == i18next.language;
        });
        setData(events);
      });
  }, []);

  return (
    <>
      <TapahtumatMap
        resetId={
          currentId ? () => setCurrentId(currentId) : () => setCurrentId(null)
        }
        currentId={currentId}
        latt={currentPos[0]}
        lngg={currentPos[1]}
        data={data}
        map={map}
      />
      <AlaValikko
        setPage={() => setPage(page + 1)}
        currentId={(id) => setCurrentId(id)}
        move={(lat, lng) => handleChange(lat, lng)}
        data={data.features}
      />
    </>
  );
};

export default TapahtumatPage;
