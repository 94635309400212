import React from 'react';
import './switchcheck.styles.css';

const SwitchAll = ({handleAll, isAll}) => {
  return (
    <div style={{position: "relative", top: 0, float: "right", marginRight: 15}}>
        <button style={{
          width: 50,
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 20,
          fontSize: 8,
        }} className={isAll ? "btn btn-danger" : "btn btn-success"} onClick={handleAll}>{isAll ? "Kaikki pois" : "Kaikki päälle"}</button>
    </div>
    );
}

export default SwitchAll;
