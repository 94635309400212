//Tapahtumat -sivun alavalikko container alavalikkokorteille

import React, {useState} from 'react';
import AlaValikkoCard from './alavalikko.card.component';
import './alavalikko.style.css';
import InfoContainer from '../infocontainer/infocontainer.component';
import imageurl from '../../assets/pictures/Salopic.jpg'

const AlaValikko = ({data, move, currentId, onScroll, setPage}) => {

  const [infoShow, setInfoShow] = useState(false);
  const [isAlavalikko, setIsAlavalikko] = useState(true);

  //Schema Alavalikko kortin infolle
  const [currentInfoData, setCurrentInfoData] = useState({
    title: "",
    date: "",
    link: "",
    id: "",
    image: "",
    latlng: []
  })

  const toggleInfo = (item) => {
      setCurrentInfoData({
        title: item.properties.name,
        date: item.properties.date,
        link: item.properties.url,
        id: item.properties.id,
        image: item.properties.image,
        latlng: [item.geometry.coordinates[1], item.geometry.coordinates[0]]
      })
    setInfoShow(true);
  }

  const handleToggle = () => setInfoShow(false);

  const scrollToEnd = () => {
    setPage()
  }

  const check = () =>{
    const divv = document.querySelector("#alavalikko");
    if(divv.scrollLeft + divv.clientWidth >= divv.scrollWidth){
      scrollToEnd();
    }
  }



  return (
    <div>

      {infoShow
      ? <InfoContainer
          data={currentInfoData}
          handleToggle={handleToggle}
        />
      : null
      }

      <div
      onScroll={check}
      className="alavalikko"
      id="alavalikko"
      style={isAlavalikko
      ? {
        position: 'absolute',
        display: "flex",
        overflow: "scroll",
        flexDirection: "flex-end",
        float: 'right',
        width: "100%",
        // backgroundColor: "rgba(115,209,1, 0.6)",
        bottom: 0,
        paddingTop: 10,
        paddingBottom: 5,
        }
      : {
        position: 'absolute',
        display: "flex",
        overflow: "scroll",
        flexDirection: "flex-end",
        float: 'right',
        width: "100%",
        // backgroundColor: "rgba(0, 75,40, 0)",
        bottom: 0,
        paddingTop: 25,
        paddingBottom: 5,
      }}>

      {isAlavalikko
      ? data.sort((a,b) => {
        return Date.parse(a.properties.date) - Date.parse(b.properties.date);
        
      }).map((item) => {
        if(Date.parse(item.properties.date) >= Date.now()){
          return (
              <AlaValikkoCard id={item.properties.id} currentId={() => currentId(item.properties.id)} move={() => move(item.geometry.coordinates[1], item.geometry.coordinates[0])} toggleInfo={() => toggleInfo(item)} title={item.properties.name} image={item.properties.image} date={item.properties.date} latlng={[item.geometry.coordinates[1], item.geometry.coordinates[0]]}/>
            )
          }
        })
        : null
      }
      </div>
      <div

      style={{
        backgroundColor: "white",
        position: "absolute",
        width: "10%",
        left: "45%",
        height: 35,
        right: 10,
        bottom: 2,
        padding: 2,
        // boxShadow: "2px 2px 10px black",
        borderRadius: 10,
        backgroundColor: "rgba(0,0,0,0)"

      }}>

      </div>
    </div>
  );
}

export default AlaValikko;
