//Vasen Päänavigointi valikko ja Yläpalkki

import React, { useState, useEffect } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import {IoBusinessOutline} from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './navbar-left.style.css';
import { IconContext } from 'react-icons';
import NavbarLeftLink from './navbar-left-link.component';
import CurrentPageInfo from '../currentpagename/currentpagename.component';
import { useLocation } from 'react-router-dom';
import TopRightLogo from '../toprightlogo/toprightlogo.component';
import middleLogo from '../../assets/pictures/etusivulogo.png';
import middleLogoInternational from '../../assets/pictures/etusivulogoint.png';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';



const Navbar = (props) => {
  const [currentLoc, setCurrentLoc] = useState();
  const location = useLocation();
  const  [t, i18n] = useTranslation("global")
  const langs = {
    en:{ nativeName: "English"},
    fi:{ nativeName: "Suomi"}
  }
  //Ottaa endpointin ja muuttaa sen Nimeksi
  const convertName = (name) => {
    switch(name){
      case "/":
        return "";
        // break;
      case "/tiedotteet":
        return t("components.news");
        // break;
      case "/tapahtumat":
        return t("components.events");
        // break;
      case "/liveinfo":
        return "Liveinfo";
        // break;
      case "/liikuntapaikat":
        return t("components.fitness");;
      case "/reitit":
        return t("components.routes");
      case "/harrastukset":
        return "Harrastukset";
      case "/kurpitsa":
        return "Kurpitsaviikot";
        //break;
      case "/liikenne":
        return t("components.transportation");
        // break;
      case "/retkeily":
        return t("components.nature");
        // break;
      case "/nahtavyydet":
        return t("components.culture");
        // break;
      case "/ruokajajuoma":
        return t("components.food");
        // break;
      case "/ostokset":
        return t("components.shopping");
        // break;
      case "/majoitus":
        return t("components.housing");
        // break;
      case "/kokousjajuhla":
        return t("components.meetings");
        // break;
      case "/yritykset":
        return "Yritykset";
        // break;
      case "/kyselyt":
        return t("components.surveys");
        // break;
      case "/vikailmoitukset":
        return t("components.feedback");
      case "/tietoasovelluksesta":
        return t("components.information");
      default:
        return "";
        // break;
      }
  } 

  useEffect(() => {
    let name = location.pathname;
    let newname = convertName(name);
    setCurrentLoc(newname);
  }, [location]);

  return (
    <>
      <CurrentPageInfo title={currentLoc}/>
      <IconContext.Provider value={{color: '#fff' }}>
        <div className='navbar' 
          style={{
            position: "fixed",
            width: "100%",
          }}>
          <Link to='#' className='menu-bars'>
            {props.isSidebar
            ? <AiIcons.AiOutlineClose onClick={props.toggleSidebar}/>
            : <FaIcons.FaBars onClick={props.toggleSidebar} />
            }
          </Link>
          
          <Link to="/">
            <TopRightLogo />
          </Link>

          {location.pathname === "/" ? <img src={(i18n.language == "fi") ? middleLogo : middleLogoInternational } style={{position: "relative", margin: "-5px auto", width: 180, right: 15}}></img> : null}
        </div>
        <nav className={props.isSidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={props.toggleSidebar}>
            {/* <li className='navbar-toggle'>
        
              <Link to='#' className='menu-bars'>
                <AiIcons.AiOutlineClose  onClick={props.toggleSidebar}/>
              </Link>
            </li> */}
            {SidebarData.map((item, index) => {
              return (
                <NavbarLeftLink key={index} item={item} index={index} />
              );
            })}

            <div className="external-link">
              <IoBusinessOutline />
              <a href="https://visitsalo.fi/yhteistyokumppaneille/#ac_visitsalo.fi+-sivusto" target="_blank">{t("components.corporate")}</a>
            </div>
            <div className='language-switch'>
                <button type='submit' key="fi" onClick={() => {i18n.changeLanguage("fi"); localStorage.setItem("i18nextLng", "fi");window.location.reload(false); }} disabled={i18n.resolvedLanguage === "fi"}>Suomi</button>
                <button type='submit' key="en" onClick={() => {i18n.changeLanguage("en"); localStorage.setItem("i18nextLng", "en");window.location.reload(false);}} disabled={i18n.resolvedLanguage === "en"}>English</button>
            </div>
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;