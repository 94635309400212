import React from 'react';
import './specific-form.css';
import {useState} from 'react'
import axios from 'axios';

const SpesificFeedbackForm = ({kyselyData, handleToggle}) => {

  const [dataForPost, setDataForPost] = useState({
    QuestionId: "",
    QuestionText: "",
    Answers: [],
    TextAnswers: []
  })

  const [tekstiVastaus, setTekstiVastaus] = useState("default")

  const postData = (dataa) => {

    const url = process.env.REACT_APP_DATABASE_URL + "SetAnswers.php"
    // const url = "/kysely/kyselyt/database/SetAnswers.php";
    axios({
      method: "POST",
      url: url,
      data: dataa,
      params: {"City": process.env.REACT_APP_CITY}
    })
    .then(response => console.log(response))
    .catch(error => console.log(error))
  }

  const handlePost = (e) => {
    e.preventDefault();
    postData(dataForPost);
    console.log("POSTED: ", dataForPost)
    handleToggle()
    
  }

  return (  
    <div>
      {kyselyData.Type === "MoVal" 
      ? <div className="specific-form">
        <h5>{kyselyData.Name}</h5>
        <p className="question-text">{kyselyData.QuestionText}</p>
        <div className="answer-container">
        {
          kyselyData.Answers.map(item => (
            <div className="answer-line" key={item.AnswerID}>
              <p style={{
                fontSize: "min(3vw, 15px)"
              }}>{item.AnswerText}</p>
              <input onChange={() => setDataForPost({...dataForPost, 
                QuestionId: item.QuestionID,
                QuestionText: kyselyData.QuestionText,
                Answers: [...dataForPost.Answers, item.AnswerID],
                              
              })} type="radio" name={item.answerID} id={item.answerID} title={item.AnswerText} />
            </div>
          ))
        }
        </div>
        <button 
          onClick={(e) => handlePost(e)}
          className="btn" style={{
          height: 40,
          borderRadius: 30,
          marginBottom: -30,
          width: "80%",
          backgroundColor: "rgb(13, 104, 139)",
          color: "white"
        }}>Lähetä</button>
        </div>
      :kyselyData.Type === "Radio"
      ?
        // Make Radio form here
        <div className="specific-form">
        <h5>{kyselyData.Name}</h5>
        <p className="question-text">{kyselyData.QuestionText}</p>
        <div className="answer-container">
        {
          kyselyData.Answers.map(item => (
            <div className="answer-line" key={item.AnswerID}>
              <p style={{
                fontSize: "min(3vw, 15px)"
              }}>{item.AnswerText}</p>
              <input onChange={() => setDataForPost({...dataForPost, 
                QuestionId: item.QuestionID,
                QuestionText: kyselyData.QuestionText,
                Answers: [item.AnswerID],
                              
              })} type="radio" name="answer" id={item.answerID} title={item.AnswerText} />
            </div>
          ))
        }
        </div>
        <button 
            onClick={(e) => handlePost(e)}
            className="btn" style={{
            height: 40,
            borderRadius: 30,
            marginBottom: -30,
            width: "80%",
            backgroundColor: "rgb(13, 104, 139)",
            color: "white"
          }}>Lähetä</button>
        </div>
      :kyselyData.Type === "Text"
      ?
        // Make Text form here
        <div className="specific-form">
        <h5>{kyselyData.Name}</h5>
        <p className="question-text">{kyselyData.QuestionText}</p>
        <div className="answer-container">
        <textarea
          onChange={(e) => {
            setTekstiVastaus(e.target.value);
            setDataForPost({...dataForPost, TextAnswers: [{QuestionID: kyselyData.QuestionIdd, Text: tekstiVastaus}]})
          }}
          placeholder="Anna palaute" 
          id="textarea" 
          style={{
            width: "80%",
            marginTop: 30,
            minHeight: 100,
            maxHeight: 100,
            padding: 10,
            borderRadius: 10
          }}
        />
        </div>
        <button
          onClick={(e) => {
            console.log(kyselyData)
            handlePost(e);
          }}
          className="btn" style={{
            height: 40,
            borderRadius: 30,
            marginBottom: -30,
            width: "80%",
            backgroundColor: "rgb(13, 104, 139)",
            color: "white"
          }}>Lähetä</button>
        </div>
      : null
      }
    </div>
  )
}

export default SpesificFeedbackForm;